.saver {
  z-index: 1000;
  color: #141414;
  background: none;
  border: 3px solid;
  width: 170px;
  padding: 20px;
  font-size: 30px;
  font-weight: 400;
  position: fixed;
  bottom: 10px;
  left: 10px;
}

.saver:hover, .saver:focus {
  color: pink;
  background: #fb8da0;
  border: 3px solid #0000;
  font-weight: 600;
}

.grabcursor {
  cursor: grab;
}
/*# sourceMappingURL=index.30dbf435.css.map */
