
.saver{
    position: fixed;
    padding: 20px;
    left: 10px;
    bottom: 10px;
    font-size: 30px;
    width: 170px;
    z-index:1000;
  }
  
  .saver {
    font-weight: 400;
    background: none;
    color: #141414;
    border: 3px solid;
  }
  
  .saver:hover, .saver:focus {
    background: #fb8da0;
    color: #FFC0CB;
    font-weight: 600;
    border: 3px solid transparent;
  }
  
  .grabcursor{
    cursor: grab;
  }
  